import { defineStore } from 'pinia';
import { sessionRequest, initiateTeaser, performerTimedOut, cancel, deleteRequest, initiateVideo, endSession, clientSeen, startCall } from '@/api/session';
import { useUserStore } from './user';
import { useChatStore } from './chat';
import { usePerformerStore } from './performer';
import { useTeaserStore } from './teaser';
import { useAlertsStore } from './alerts';
import notifications, { type VideoChatUpdate } from '@/socket';
import { usePaymentStore } from './payment';
import type { Stream, SessionType, initiatePayload } from '@/ontology/stream';
import { among, match, minutes, seconds } from '@/utils';
import type { Routed } from '@/router';
import i18n from './../translations';
import type { Performer, ServiceStatus, Services } from '@/ontology/performer';
import { useVideoCallStore } from './videocall';
import { StartupErrors } from '@/api/session/utils';
import { useMatomo } from '@/composables/useMatomo';

export type Status =
    | 'idle' //nothing happens
    | 'checking' //initial request, checks if all is ok; payment is ok, guy isn't blocked..
    | 'authorizing' //only valid for cam: request is sent to the performer and now we wait
    | 'awaiting-teaser-end' //when switching from teaser to cam, we want teaser to be ended before we continue initiating
    | 'initiating' //fetching the stream parameters
    | 'initializing' //starting the stream
    | 'limbo' //we need a user click to really start the stream
    | 'active' // stream is active, payment begins
    | 'ending'
    | 'ended'
    | 'error';

interface State extends Routed {
    status: Status;
    switching: boolean;
    endReason: string;
    playStream?: Stream;
    publishStream?: Stream;
    //performer ID
    performer: number;
    type: SessionType;
    screenSize: 'full' | 'zoom';
    viewState: 'halfscreen' | 'fullscreen';
    timeOuts: {
        keepAlive: any;
        performerTimeout: any;
        ending: any;
    };
}

function statusChangeValid(newValue: Status, oldValue: Status): boolean {
    if (newValue == oldValue) {
        return false;
    }

    if (newValue == 'ending' && ['idle', 'ended'].includes(oldValue)) {
        return false;
    }

    return true;
}

export const useCamStore = defineStore({
    id: 'cam',
    state: (): State => ({
        status: 'idle',
        switching: false,
        endReason: '',
        playStream: undefined,
        publishStream: undefined,
        performer: -1,
        type: 'none',
        screenSize: 'full',
        viewState: useUserStore().mobile ? 'fullscreen' : 'halfscreen',
        timeOuts: {
            keepAlive: -1,
            performerTimeout: -1,
            ending: -1
        }
    }),
    actions: {
        initialize() {
            notifications.subscribe('videoChat', this.handleNotification);
            notifications.subscribe('clientstream', this.handleNotification);
            notifications.subscribe('disconnected', this.handleNotification);
            notifications.subscribe('status_change', this.handleNotification);
        },

        handleNotification(update: VideoChatUpdate) {
            // reconnect fix add 'update.type !== 'RECONNECTED' &&
            if (update.type !== 'RECONNECTED' && (this.playStream && this.playStream.id != update.id)) {
                return;
            }

            //translates a socket message to an action to be dispatched when the rule matches.
            //Rules are checked from top to bottom
            const rules = [
                {
                    when: { type: 'RESPONSE', message: 'HANGUP' },
                    do: () => this.ended('HANGUP')
                },
                {
                    when: { type: 'RESPONSE', message: 'MAIN_ENDED' },
                    do: () => this.ended('PERFORMER_END')
                },
                {
                    when: { type: 'RECONNECTED', value: { type: 'PLAY' } },
                    do: () => this.reconnectPlayStream(update.value.rtmpLink)
                },
                {
                    when: { type: 'RECONNECTED', value: { type: 'PUBLISH' } },
                    do: () => this.reconnectPublishStream(update.value.rtmpLink)
                },
                {
                    when: {
                        inStatus: among(['active', 'ending']),
                        type: 'RESPONSE',
                        message: 'CLICK',
                        value: false
                    },
                    do: () => this.ended('PERFORMER_END')
                },
                {
                    when: {
                        inStatus: 'awaiting-teaser-end',
                        sender: 'teaser',
                        value: 'ended'
                    },
                    do: () => this.initiateVideo()
                },
                {
                    when: { type: 'RESPONSE', value: true, message: 'TIMEOUT' },
                    do: () => this.ended('CLIENT_TIMEOUT')
                },
                //TODO: is this for real yo?
                {
                    when: {
                        inStatus: among(['active', 'ending']),
                        type: 'RESPONSE',
                        message: 'DISCONNECT',
                        value: false
                    },
                    do: () => this.ended('PERFORMER_DISCONNECTED')
                },
                {
                    when: {
                        inStatus: among(['active', 'ending']),
                        type: 'RESPONSE',
                        message: 'BROKE'
                    },
                    do: () => this.ended('CLIENT_BROKE')
                },
                {
                    when: { inStatus: 'authorizing', value: true },
                    do: () => this.initiateVideo(update as any)
                },
                {
                    when: { inStatus: 'authorizing', _stateChange: 'REJPERF' },
                    do: () => this.ended('PERFORMER_REJECT')
                },
                {
                    when: { inStatus: 'authorizing', value: 'DISCONNECT' },
                    do: () => this.ended('PERFORMER_DISCONNECTED')
                },
                //when the websocket connection of this user is lost
                {
                    when: { inStatus: 'authorizing', type: 'SOCKET' },
                    do: () => this.ended('SOCKET_DISCONNECTED')
                },
                //all other scenario's while authorizing should result in undefined
                {
                    when: { inStatus: 'authorizing' },
                    result: undefined as any
                },
                {
                    when: { message: 'CLICK', value: false },
                    result: { action: 'cancel', label: 'PERFORMER_END' }
                },
                {
                    when: { message: 'DISCONNECT', value: false },
                    result: { action: 'cancel', label: 'PERFORMER_END' }
                }
            ];

            const toMatch = { ...update, ...{ inStatus: this.status } };

            const rule = rules.find(check => match(toMatch, check.when));
            if (rule && rule.do) {
                rule.do();
            }
        },

        async initiate(performerId: number, payload: initiatePayload) {
            //just to make sure nothing crashes when peek switching too quickly
            if (this.switching) {
                return;
            }

            // check if got payload
            if (!payload) {
                return;
            }

            //stop any active sessions
            if (!['ended', 'error', 'idle'].includes(this.status)) {
                this.switching = true;
                await this.end();
            }

            this.endReason = '';
            this.performer = performerId;
            this.type = payload.type;
            return this.checkPayment(payload.ivrCode ? 'ivr' : 'credits', payload.ivrCode, payload.name);
        },

        async checkPayment(paymentType?: 'credits' | 'ivr', ivrCode?: string, name?: string) {
            if (!(this.performer && this.type)) {
                throw new Error(`please call initiate first.`);
            }

            const account = useUserStore().account;
            if (name) {
                account.username = name;
            }

            const payment = usePaymentStore();

            //if no payment details are provided at all, just the details from the payment store.
            //happens when switching between sessions
            if (!paymentType) {
                paymentType = payment.type;
                ivrCode = payment.code;
            }

            //if still no payment type is provided, stop right here
            if (!paymentType) {
                throw new Error('cam store: please provide a payment type');
            }

            if (paymentType == 'ivr' && !ivrCode) {
                return { code: StartupErrors.no_code, message: 'missing ivr code' };
            }

            payment.authorize(this.type, paymentType, ivrCode);

            this.setStatus('checking');

            //make sure there's a socket connection before we continue..
            const connected = await notifications.connection();

            if (connected.error) {
                this.setStatus('idle');
                //yeah seems silly to send a message through the socket when there's a no-socket-error.
                //the message will be queued and sent once the socket succeeds at reconnecting
                notifications.sendEvent({
                    content: [{ event: 'no-socket-error', client: account.id }],
                    event: 'udplog',
                    receiverType: undefined
                });
                return { message: i18n.global.t(`profile.session.endreason.${connected.error}`), code: 500 };
            }

            //use the accounts ivrode for videocall credit calls
            if (paymentType == 'credits' && this.type == 'videocall') {
                ivrCode = account.creditsIvrCode?.toString();
            }

            const { error: requestError } = await sessionRequest(this.type, {
                performerId: this.performer,
                clientId: account.id!,
                name: account.username || 'anonymous',
                ivrCode
            });

            if (requestError) {
                const statusErrors = ['Performer nicht verfügbar', 'Performer not available', 'Performer niet beschikbaar'];
                if (statusErrors.includes(requestError.message)) {
                    //let's refresh the performer if this client thinks the performer is still available
                    const performers = usePerformerStore();
                    const performer = performers.getById(this.performer);
                    let service = undefined;
                    if (this.type == 'chat') {
                        service = 'cam';
                    } else if (this.type == 'vipchat') {
                        service = 'vipcam';
                    } else {
                        service = this.type;
                    }
                    if (performer.services[service].status == 'available') {
                        performers.loadPerformer(performer.advertNumber);
                        notifications.sendEvent({
                            content: [{ event: 'status-error', client: account.id, performer: this.performer, service }],
                            event: 'udplog',
                            receiverType: undefined
                        });
                    }
                }
                //reset ivr code..
                if (requestError.code === 494 && ivrCode) {
                    payment.code = undefined;
                }

                this.setStatus('idle');
                payment.authorizationFailed(requestError.message);
                return requestError;
            }

            //if something happens while checking, the rest is no longer valid. So return.
            if (this.status != 'checking') {
                return;
            }

            if (this.type == 'peek') {
                return this.initiateVideo();
            } else {
                //The performer has to authorize the request when chatting or videocalling. Let's wait. But not indefinitely
                this.setStatus('authorizing');
                this.timeOuts.performerTimeout = setTimeout(this.performerTimedOut, minutes(1));
            }
        },

        //called when a user ends a session while the session status is not yet active.
        async cancel() {
            if (!['checking', 'authorizing'].includes(this.status)) {
                //canceling should only happen while the session is not yet active
                return;
            }
            this.setStatus('ending');
            //peek sessions can't be canceled
            if (this.type != 'peek') {
                const { error } = await cancel(useUserStore().account.id!, this.performer);

                if (error) {
                    //apparently canceling / ending was not allowed?
                    //TODO: what to do?
                }
            }

            this.endReason = 'CANCELED';
            this.setStatus('ended');
        },

        async performerTimedOut() {
            //ignore timeouts that aren't relevant anymore
            if (this.status != 'authorizing') {
                return;
            }

            const { error } = await performerTimedOut(useUserStore().account.id!, this.performer);
            //TODO: what to do with this error?

            this.ended('PERFORMER_TIMEOUT');
        },

        async initiateVideo(socketMSG?: any) {
            if (useTeaserStore().status == 'active') {
                this.setStatus('awaiting-teaser-end');
                return;
            }
            const account = useUserStore().account;
            const payment = usePaymentStore();
            this.setStatus('initiating');

            const performers = usePerformerStore();
            let performer = performers.getById(this.performer);
            if (!performer) {
                //we'll need to load the performer by id...
                performer = await performers.loadPerformerById(this.performer);
            }

            //use the accounts ivrode for videocall credit calls
            const ivrCode = payment.type == 'credits' && this.type == 'videocall' ? account.creditsIvrCode?.toString() : payment.code;

            const payload: any = {
                advert: performer.advertNumber,
                clientId: account.id!,
                ivrCode,
                name: account.username || 'anonymous',
                sessionType: this.type
            };

            if (socketMSG) {
                payload['type'] = socketMSG.sessionType;
            }

            const { error: initiateError, result } = await initiateVideo(payload);

            if (initiateError) {
                this.setStatus('error');
                return initiateError;
            }

            if (!result) {
                throw new Error('Impossible');
            }

            this.setStatus('initializing');

            if (this.type == 'videocall') {
                useVideoCallStore().started();
            }

            this.$patch({
                playStream: {
                    id: result.id,
                    name: result.playStream,
                    token: result.playToken,
                    server: result.playWowza,
                    status: 'idle',
                    attempt: 1
                },
                publishStream: {
                    name: result.publishStream,
                    token: result.publishToken,
                    server: result.publishWowza,
                    status: 'idle',
                    attempt: 1
                }
            });
        },

        async keepAlive() {
            if (this.status != 'active') {
                return;
            }
            const { error } = await clientSeen();
            if (error && error.code == 401) {
                this.ended('OOPS');
            }
            //TODO: so what if another error that 401 is thrown?
        },

        //called when this user ends the session
        async end() {
            //why end a session that is already end(ed)(ing)?
            if (this.status == 'ended') {
                this.setStatus('idle');
                return;
            }

            if (['ending', 'idle'].includes(this.status)) {
                return;
            }

            if (['checking', 'authorizing'].includes(this.status)) {
                //well.. this really is canceling, not ending.
                await this.cancel();
                return;
            }

            this.endReason = 'CLICK';
            this.setStatus('ending');
            const { error } = await endSession();
            if (error) {
                //TODO: handle this nearly impossible error; probably an error cause the end request was after
                // the session was ended already?
            }

            // Set to ended or in videochat/chat to idle
            if (['videocall', 'chat', 'teaser'].includes(this.type)) {
                this.setStatus('idle');
            } else {
                this.setStatus('ended');
            }

            // Reset Chat messages
            const reset = useChatStore().resetMessages();
        },

        //called when the system tells this client the session has ended, mostly in response to a websocket notification
        ended(reason: string) {
            if (reason == 'PERFORMER_REJECT') {
                deleteRequest(this.performer);
            }

            if (!this.endReason) {
                this.endReason = reason;
                useAlertsStore().errorMessage(`profile.session.endreason.${reason}`);
            }
            this.setStatus('ended');

            // Reset Chat messages
            const reset = useChatStore().resetMessages();
        },

        async next() {
            const peekers = usePerformerStore().getSlice('peekers');
            const ix = peekers.items.indexOf(this.performer);
            let theNext = ix + 1;
            if (theNext >= peekers.items.length) theNext = 0;

            return await this.initiate(peekers.items[theNext], { type: 'peek' });
        },
        toggleScreenSize() {
            this.screenSize = this.screenSize === 'full' ? 'zoom' : 'full';
        },
        setStatus(value: Status) {
            if (!statusChangeValid(value, this.status)) {
                return;
            }

            this.status = value;
            //at some point, you jump out of the switching status..
            this.switching = this.stillSwitching();

            notifications.sendLocalEvent('status_change', {
                sender: 'cam',
                value
            });

            const performers = usePerformerStore();

            if (this.status != 'authorizing') {
                this.timeOuts.performerTimeout && clearTimeout(this.timeOuts.performerTimeout);
            }

            if (this.status == 'ended' && this.router && this.performer > -1 && (this.router.currentRoute as any)._value.name != 'teaser') {
                //only return to the profile route when the route is still 'about' the current performer
                const currentAdvert = performers.performers[this.performer].advertNumber.toString();
                const route = (this.router.currentRoute as any)._value;
                if (route.params.advert == currentAdvert) {
                    this.router.push({ name: 'profile', params: { advert: currentAdvert } });
                }
            }

            //time to reset some things
            if (this.status == 'ended') {
                clearTimeout(this.timeOuts.performerTimeout);
                clearInterval(this.timeOuts.keepAlive);
                clearTimeout(this.timeOuts.ending);
                this.playStream = undefined;
                if (!this.isThisSessionRoute()) {
                    //sniff sniff? What's that smell?
                    setTimeout(() => this.setStatus('idle'), 0);
                }
            }

            if (this.status == 'ending') {
                this.timeOuts.ending = setTimeout(() => {
                    if (this.status != 'ending') return;
                    this.ended('DISCONNECTED');
                }, seconds(1));
            }

            if (this.status == 'idle') {
                this.$reset();
            }
        },

        stillSwitching() {
            return this.switching && ['choosing_payment', 'checking', 'initiating', 'initializing', 'limbo', 'ending', 'ended'].includes(this.status);
        },

        //check to see if we can just fall through the ended status
        isThisSessionRoute() {
            const route = (this.router!.currentRoute as any)._value;
            if (this.switching) {
                return true;
            }

            if (route.name != 'videochat') {
                return false;
            }

            if (!(this.performer > 0)) {
                return false;
            }

            const { advert } = route.params;
            const current = usePerformerStore().getById(this.performer);

            return current.advertNumber == parseInt(advert as string);
        },

        reconnectPlayStream(server: string){
            if(!this.playStream){ return; }
            this.playStream.server = server;
            this.playStream.attempt = this.playStream.attempt ? this.playStream.attempt + 1 : 1;
        },

        reconnectPublishStream(server: string){
            if(!this.publishStream){ return; }
            this.publishStream.server = server;
            this.publishStream.attempt = this.publishStream.attempt ? this.publishStream.attempt + 1 : 1;
        },

        playStreamStatusChange(newValue: string, error?: string) {
            const { trackEvent } = useMatomo();

            if (!this.playStream) {
                //the stream is reset before a final status message can come in for the stream
                return;
            }

            this.playStream.status = newValue;

            //I don't care what the new status of a stream is, if this session is terminating already
            if (['ending', 'ended'].includes(this.status)) {
                return;
            }

            if (newValue == 'limbo') {
                this.setStatus('limbo');
            }

            if (newValue == 'error' && error) {
                this.ended('start-error');
            }
            //I don't care about intermittent statuses between initializing and active,
            if (newValue == 'active') {
                this.setStatus('active');
                this.timeOuts.keepAlive = setInterval(this.keepAlive, seconds(5));
                //"really" activate that session
                notifications.sendEvent({
                    receiverType: 'ROLE_PERFORMER',
                    receiverId: this.performer,
                    event: 'videoChat',
                    content: {
                        type: 'START_TIMER_DEVICE',
                        clientId: useUserStore().account.id!,
                        performerId: this.performer,
                        value: undefined
                    }
                });
                trackEvent('Service', 'CamStoreActive');
            }

            if (['destroying'].includes(newValue)) {
                //this message is usually received before the real reason is communicated through the socket server
                //so we put the status to 'ending', the status that times out to 'ended' after 1 second
                this.setStatus('ending');
            }
        },
        publishStreamStatusChange(newValue: string) {
            if (!this.publishStream) {
                return;
            }
            this.publishStream.status = newValue;
        }
    }
});
