const config = {
    BaseUrl: enved('BaseUrl'),
    FullApiUrl: enved('FullApiUrl'),
    SocketUrl: enved('SocketUrl'),
    Themename: enved('Themename'),
    ImageUrl: enved('ImageUrl'),
    SnapshotUrl: enved('SnapshotUrl'),
    //deprecated
    JsmpegUrl: enved('JsmpegUrl'),
    //deprecated
    JanusmpegUrl: enved('JanusmpegUrl'),
    StorageKey: enved('StorageKey'),
    Country: enved('Country'),
    VodServer: enved('VodServer'),
    //Deprecated
    H5FlashSwf: enved('H5FlashSwf'),
    FreeRegister: enved('FreeRegister') === 'true',
    GridCache: parseFloat(enved('GridCache')),
    //Deprecated
    Banner: enved('Banner') === 'true',
    AllowedCreditcardCountries: enved('AllowedCreditcardCountries') === 'all' ? 'all' : enved('AllowedCreditcardCountries').split(','),
    DefaultLanguage: enved('DefaultLanguage'),
    GoogleTagCode: enved('GoogleTagCode'),
    AgeCheck: enved('AgeCheck') === 'true',
    Hotjar: enved('Hotjar') === 'true',
    Version: enved('Version'),
    CCEnvironment: enved('CCEnvironment'),
    CCEUR: enved('CCEUR'),
    CCUSD: enved('CCUSD'),
    CCGBP: enved('CCGBP'),
    MatomoSiteID: enved('MatomoSiteId'),
    Sitename: enved('Sitename')
};

export default config;

function enved(key: string): string {
    return import.meta.env[`VITE_${key}`] || '';
}

export function gtag(any1: any, any2: any, any3: any) {
    window.dataLayer.push(arguments);
}

if (window.loadTagManager) {
    window.dataLayer = window.dataLayer || [];

    // Default consent settings
    const defaultConsent = {
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        personalization: 'denied',
        analytics_storage: 'denied',
        security_storage: 'denied',
        functionality_storage: 'denied'
    };

    // Get previous set cookies
    const cookieageName = `${config.StorageKey}.cookies`;
    if (window.localStorage.hasOwnProperty(cookieageName)) {
        const cookies = JSON.parse(window.localStorage.getItem(cookieageName) as any);

        if (cookies) {
            cookies.forEach((cookie: { name: string; value: any }) => {
                if (cookie.name === 'marketing' && cookie.value) {
                    defaultConsent['ad_storage'] = 'granted';
                    defaultConsent['ad_user_data'] = 'granted';
                    defaultConsent['ad_personalization'] = 'granted';
                } else if (cookie.name === 'essential' && cookie.value) {
                    defaultConsent['analytics_storage'] = 'granted';
                    defaultConsent['security_storage'] = 'granted';
                    defaultConsent['functionality_storage'] = 'granted';
                } else if (cookie.name === 'static' && cookie.value) {
                    defaultConsent['personalization'] = 'granted';
                }
            });
        }
    }

    gtag('consent', 'default', defaultConsent);

    if (defaultConsent['ad_storage'] === 'denied') {
        gtag('set', 'ads_data_redaction', false);
        gtag('set', 'url_passthrough', true);
    }

    window.loadTagManager(window, document, 'script', 'dataLayer', config.GoogleTagCode);
}
